import React from "react";
import PropTypes from "prop-types";

import configs from "../../utils/configs";
import { useLogo } from "../styles/theme";
import univeriumLogo from "../../assets/images/univerium/logo.png"

export function AppLogo({ className, forceConfigurableLogo }) {
  const logo = useLogo();
  if (!forceConfigurableLogo) {
    return <img className={className} width={144} src={univeriumLogo}/>;
  } else {
    return <img className={className} width={144} alt={configs.translation("app-name")} src={logo} />;
  }
}

AppLogo.propTypes = {
  className: PropTypes.string,
  forceConfigurableLogo: PropTypes.bool
};
